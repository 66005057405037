import { isMobile, bodyLock, bodyUnlock } from "./functions.js";
import { flsModules } from "./modules.js";

// Select all elements with the class "text-js" and store them in the 'elements' constant.
const elements = document.querySelectorAll(".text-js");

// Function to update the text content of selected elements based on the window width.
function updateText() {
  // Get the current width of the window.
  const screenWidth = window.innerWidth;

  // Determine if it's a desktop view by comparing the window width to a threshold (768.98).
  const isDesktop = screenWidth >= 768.98;

  // Iterate through each element in the 'elements' NodeList.
  elements.forEach((element) => {
    // Depending on the view (desktop or mobile), update the text content of the element.
    const text = isDesktop ? element.dataset.desktop : element.dataset.mobile;
    element.textContent = text;
  });
}

// Call the 'updateText' function to set initial text content based on the window width.
updateText();

// Add an event listener to update the text when the window is resized.
window.addEventListener("resize", updateText);

//========================================================================================================================================================
// Code to create a line around a book when scroll page
// Select all elements with the class "drawnPath" and store them in the 'svgLines' variable.
var svgLines = document.querySelectorAll(".drawnPath");

// Function to animate SVG lines on scroll.
function drawsvgLinesOnScroll() {
  // Iterate through each SVG line element in the 'svgLines' NodeList.
  svgLines.forEach(function (svgLine) {
    // Get the total length of the SVG path.
    var length = svgLine.getTotalLength();

    // Set the stroke dash array and offset to the total length to hide the path initially.
    svgLine.style.strokeDasharray = length;
    svgLine.style.strokeDashoffset = length;

    // Get the position and dimensions of the SVG line element within the viewport.
    var rect = svgLine.getBoundingClientRect();

    // Get the height of the viewport.
    var windowHeight = window.innerHeight;

    // Check if the SVG line is currently within the viewport.
    if (rect.top < windowHeight && rect.bottom >= 0) {
      // Calculate the scroll percentage based on the SVG line's position within the viewport.
      var scrollpercent =
        (windowHeight - rect.top) / (rect.height + windowHeight);

      // Calculate the length to draw based on the scroll percentage, with a factor of 3 for smoother animation.
      var draw = length * scrollpercent * 3;

      // Update the stroke dash offset to reveal the path as the user scrolls.
      if (draw >= length) {
        svgLine.style.strokeDashoffset = 0; // Fully reveal the path.
      } else {
        svgLine.style.strokeDashoffset = length - draw; // Partially reveal the path.
      }
    }
  });
}

// Add an event listener to update SVG lines when the user scrolls.
window.addEventListener("scroll", drawsvgLinesOnScroll);

// Add an event listener to also trigger the animation when the page is fully loaded.
window.addEventListener("load", drawsvgLinesOnScroll);

//========================================================================================================================================================

const zoomedImgs = document.querySelectorAll(".zoom-js");
const overlay = document.querySelector(".article__overlay");
let isZoomed = false;

zoomedImgs.forEach(function (zoomedImg) {
  zoomedImg.addEventListener("click", function () {
    const windowWidth = window.innerWidth;

    if (!isZoomed && windowWidth >= 991.98) {
      const zoomedImgElement = document.createElement("img");
      zoomedImgElement.src = this.src;
      zoomedImgElement.alt = this.alt;
      zoomedImgElement.classList.add("article__zoomed-img");

      overlay.innerHTML = "";
      overlay.appendChild(zoomedImgElement);

      overlay.style.display = "block";
      isZoomed = true;
      bodyLock();

      overlay.addEventListener("click", function (event) {
        if (event.target === overlay) {
          overlay.style.display = "none";
          isZoomed = false;
          bodyUnlock();
        }
      });
    }
  });
});

const scrollContainer = document.getElementById("scrollContainer");
const loadingIndicator = document.getElementById("loadingIndicator");
let page = 1;

if (scrollContainer) {
  // function fetchItems(pageIndex = 1) {
  //   return fetch(`https://someapicall/load-people?page=${pageIndex}`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .catch((error) => {
  //       console.error("Something went wrong:", error);
  //     });
  // }

  function fetchItems(pageIndex = 1) {
    return Promise.resolve([
      { firstName: "John", lastName: "Doe" },
      { firstName: "Kevin", lastName: "Skinner" },
      { firstName: "Amy", lastName: "Turner" },
      { firstName: "Bernadette", lastName: "Harris" },
      { firstName: "Joanne", lastName: "Vaughan" },
      { firstName: "John", lastName: "Doe" },
      { firstName: "end", lastName: "end" },
    ]);
  }

  function loadNextPage() {
    loadingIndicator.style.display = "block";

    fetchItems(page++).then((persons) => {
      const personHTML = persons
        .map((person) => `${person.firstName} ${person.lastName}, `)
        .join("");
      scrollContainer.insertAdjacentHTML("beforeend", personHTML);
      loadingIndicator.style.display = "none";
    });
  }

  loadNextPage();

  window.addEventListener("scroll", () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    let offset;

    // Set different offset values for desktop and mobile
    if (window.innerWidth > 767) {
      // Desktop
      offset = 600;
    } else {
      // Mobile
      offset = 1000;
    }

    // Check if the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - offset) {
      loadNextPage();
    }
  });
}
