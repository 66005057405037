// Including the main style file
import "../scss/style.scss";

document.addEventListener('DOMContentLoaded', (event) => {
    // When caption 'klik aan om te vergroten' is clicked, also trigger zoom on image.
    document.querySelectorAll('.trigger-zoom-js').forEach(function(triggerZoom) {
        triggerZoom.addEventListener('click', function() {
            const imgContainer = this.closest('.article__content-img-container');
            const toClick = imgContainer.querySelector('.zoom-js');

            if (toClick) {
                toClick.click();
            }
        });
    });
});