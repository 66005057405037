/*
Documentation on work in the template:
Slider Documentation: https://swiperjs.com/
Snippet (HTML): Swiper
*/

// We connect Swider Slider with Node_Modules
// If necessary, connect additional slider modules, pointing them in {}
// Example: {Navigation, Autoplay}
import Swiper from "swiper";
import { Navigation, EffectCoverflow, Scrollbar } from "swiper/modules";
/*
Basic Slider modules:
Navigation, Pagination, Autoplay, 
EffectFade, Lazy, Manipulation
See https://swiperjs.com/
*/

// Swiper styles
// Basic styles
//import "../../scss/base/swiper.scss";
// Full set of styles scss/libs/swiper.scss
//import "../../scss/libs/swiper.scss";
// Full set of styles node_modules
import "swiper/css";

function initSliders() {
  if (document.querySelector(".books__slider")) {
    new Swiper(".books__slider", {
      modules: [Navigation, EffectCoverflow],

      slidesPerView: "auto",
      spaceBetween: 80,
      //autoHeight: true,
      loop: true,
      slideToClickedSlide: true,
      speed: 800,
      effect: "coverflow",
      centeredSlides: true,
      centerInsufficientSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: -40,
        depth: 100,
        modifier: 3,
        slideShadows: false,
      },

      navigation: {
        prevEl: ".books__button-prev",
        nextEl: ".books__button-next",
      },
      breakpoints: {
        375: {
          spaceBetween: 20,
          coverflowEffect: {
            stretch: -10,
            modifier: 5,
          },
        },
        768: {
          spaceBetween: 40,
          coverflowEffect: {
            stretch: -20,
            modifier: 4,
          },
        },
        992: {},
        1921: {},
      },
      on: {},
    });
  }
  if (document.querySelector(".testimonials__slider")) {
    new Swiper(".testimonials__slider", {
      modules: [Navigation, Scrollbar],

      slidesPerView: 3,
      spaceBetween: 16,
      //autoHeight: true,

      speed: 800,

      slideToClickedSlide: true,
      //loop: true,

      scrollbar: {
        el: ".testimonials__scrollbar",
      },

      navigation: {
        prevEl: ".testimonials__button-prev",
        nextEl: ".testimonials__button-next",
      },
      breakpoints: {
        375: {
          spaceBetween: 8,
          slidesPerView: 1.1,
        },
        489: { slidesPerView: 1.3 },
        768: {
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 16,
        },
        1220: {
          slidesPerView: 3,
        },
      },
      on: {},
    });
  }
  if (document.querySelector(".partners__slider")) {
    new Swiper(".partners__slider", {
      modules: [Navigation, Scrollbar],

      slidesPerView: "auto",
      spaceBetween: 16,
      //autoHeight: true,

      speed: 800,

      slideToClickedSlide: true,
      //loop: true,

      scrollbar: {
        el: ".testimonials__scrollbar",
        draggable: true,
      },

      navigation: {
        prevEl: ".testimonials__button-prev",
        nextEl: ".testimonials__button-next",
      },
      breakpoints: {
        375: {
          spaceBetween: 8,
        },
        489: {},
        768: {},
        992: {
          spaceBetween: 16,
        },
        1220: {},
      },
      on: {},
    });
  }
}
// Slider scroll (by Swiper Scroll Class for Slider)
function initSlidersScroll() {
  let sliderScrollItems = document.querySelectorAll(".swiper_scroll");
  if (sliderScrollItems.length > 0) {
    for (let index = 0; index < sliderScrollItems.length; index++) {
      const sliderScrollItem = sliderScrollItems[index];
      const sliderScrollBar =
        sliderScrollItem.querySelector(".swiper-scrollbar");
      const sliderScroll = new Swiper(sliderScrollItem, {
        observer: true,
        observeParents: true,
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: {
          enabled: true,
        },
        scrollbar: {
          el: sliderScrollBar,
          draggable: true,
          snapOnRelease: false,
        },
        mousewheel: {
          releaseOnEdges: true,
        },
      });
      sliderScroll.scrollbar.updateSize();
    }
  }
}

window.addEventListener("load", function (e) {
  // Launching sliders initialization
  initSliders();
  //initSlidersScroll();
});
