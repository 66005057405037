/*
(i) The code goes into the resulting file
only when the function is called,
for example, flsFunctions.spollers();
Or when the entire file is imported,
for example, import "files/script.js";
Unused (not called)
code does not go into the resulting file.

If we want to add a module,
we should uncomment it.
*/

// Enable/Disable FLS (Full Logging System) (in operation)
window["FLS"] = false;

// Including the main style file
import "../scss/style.scss";

//============================================================================================================================================================================================================================================
// React ========================================================================================================================================================================================================================================================
//============================================================================================================================================================================================================================================
// import Index from './react/Index.jsx';
//============================================================================================================================================================================================================================================

// ========================================================================================================================================================================================================================================================
// Functionality ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================
import * as flsFunctions from "./files/functions.js";

/* Checking webp support, adding the webp or no-webp class to HTML */
/* (i) necessary for correct webp display with CSS */
// flsFunctions.isWebp();
/* Adding the touch class to HTML if the browser is mobile */
// flsFunctions.addTouchClass();
/* Adding the loaded class to HTML after the page is fully loaded */
// flsFunctions.addLoadedClass();
/* Module for working with the menu (Burger) */
flsFunctions.menuInit();
/* Considering the floating panel on mobile devices with 100vh */
// flsFunctions.fullVHfix();
/* Number formatting */
// import './libs/wNumb.min.js';

/*
"Spollers" module
Snippet (HTML): spollers
*/
flsFunctions.spollers();

/*
"Tabs" module
Snippet (HTML): tabs
*/
//flsFunctions.tabs();

/*
"Show More" module
Snippet (HTML): showmore
*/
// flsFunctions.showMore();

/*
"Before/After" module
Snippet (HTML): ba
*/
// import './libs/beforeafter.js';

/*
"Wave Effect" module
Snippet (HTML):
*/
// flsFunctions.rippleEffect();

/*
"Custom Cursor" module
Documentation:
Snippet (HTML):
*/
// flsFunctions.customCursor(true);

/*
"Popups" module
Snippet (HTML): pl, pop
*/
// import './libs/popup.js'

/*
Mouse Parallax module
*/
// import './libs/parallax-mouse.js'

// ========================================================================================================================================================================================================================================================
// Form Handling ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================
import * as flsForms from "./files/forms/forms.js";

/* Form Field Handling */
/*
flsForms.formFieldsInit({
  viewPass: false,
  autoHeight: false
});
*/
/* Form Submission */
// flsForms.formSubmit();

/* "Quantity" Form Module */
// flsForms.formQuantity();

/* "Star Rating" Form Module */
// flsForms.formRating();

/* Select Handling Module */
// import './libs/select.js'

/* Calendar Handling Module */
// import './files/forms/datepicker.js'

/* (In Operation) Mask Handling Module. */
/*
Connection and configuration are performed in the file js/files/forms/inputmask.js
Documentation for use in the template:
Plugin Documentation: https://github.com/RobinHerbots/inputmask
Snippet (HTML):
*/
// import "./files/forms/inputmask.js";

/* Slider Handling Module */
/*
Connection and configuration are performed in the file js/files/forms/range.js
Documentation for use in the template:
Plugin Documentation: https://refreshless.com/nouislider/
Snippet (HTML): range
*/
// import "./files/forms/range.js";

/* Tooltip Handling Module (Tippy.js) */
/*
Plugin Tippy.js connection and configuration are performed in the file js/files/tippy.js
Documentation for use in the template:
Plugin Documentation: https://atomiks.github.io/tippyjs/
Snippet (HTML): tip (adds a tooltip attribute to an HTML tag)
*/
// import "./files/tippy.js";

// ========================================================================================================================================================================================================================================================
// Working with Sliders (Swiper) ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================
/*
Configuration of the Swiper slider plugin connection and new sliders is performed in the file js/files/sliders.js
Plugin Documentation: https://swiperjs.com/
Snippet (HTML): swiper
*/
import "./files/sliders.js";

// ========================================================================================================================================================================================================================================================
// Page Scrolling Modules ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================

/*
Changing the scrollbar design
Documentation for use in the template: Add the data-simplebar attribute to the block in HTML
Plugin Documentation: https://github.com/Grsmto/simplebar/tree/master/packages/simplebar
Snippet (HTML): 
*/
// import './files/scroll/simplebar.js';

// Lazy (Deferred) Loading of Images
// Plugin Documentation: https://github.com/verlok/vanilla-lazyload
// Snippet (HTML):
// import './files/scroll/lazyload.js';

// Observer for objects with a data-watch attribute
// Snippet (HTML):
//import "./libs/watcher.js";

// Full Page Scroll Module
// Snippet (HTML):
// import './libs/fullpage.js'

// Parallax Module
// Snippet (HTML):
// import './libs/parallax.js'

// Scroll Functionality
import * as flsScroll from "./files/scroll/scroll.js";

// Smooth Page Navigation
flsScroll.pageNavigation();

// Functionality to add classes to the header while scrolling
flsScroll.headerScroll();

// Digits Counter Animation Module
// Snippet (HTML):
// flsScroll.digitsCounter();

// ========================================================================================================================================================================================================================================================
// Gallery ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================
/*
Documentation for use in the template: 
Plugin Documentation: https://www.lightgalleryjs.com/docs/
Snippet (HTML):
*/
// import "./files/gallery.js";

// ========================================================================================================================================================================================================================================================
// Masonry Grid ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================
/*
Documentation for use in the template:
Plugin Documentation: 
Snippet (HTML):
*/
// import "./files/isotope.js";

// ========================================================================================================================================================================================================================================================
// Other Plugins ============================================================================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================

/* Dynamic Adaptation */
import "./libs/dynamic_adapt.js";

// ========================================================================================================================================================================================================================================================
// Other ========================================================================================================================================================================================================================================================
// ========================================================================================================================================================================================================================================================
/* Include files with your code */
import "./files/script.js";
//============================================================================================================================================================================================================================================
